import { backgroundTypeProp, imageProp, videoProp } from 'utils/proptypes/modules/mediaProps';

import AnimationWrapper from 'components/wrappers/AnimationWrapper';
import ArrowLink from 'components/text/ArrowLink';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import PropTypes from 'prop-types';
import React from 'react';

const Media = ({ background, backgroundRatio, backgroundType, link, mediaQuery, textColor }) => (
    <AspectWrapper position="relative" ratio={backgroundRatio}>
        <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery} />
        {link && (
            <AnimationWrapper>
                <ArrowLink color={textColor} position="absolute !important" bottom="16px" left="16px" to={link.to}>
                    {link.label}
                </ArrowLink>
            </AnimationWrapper>
        )}
    </AspectWrapper>
);

Media.propTypes = {
    background: PropTypes.oneOfType([imageProp, PropTypes.string, videoProp]).isRequired,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: backgroundTypeProp,
    link: PropTypes.exact({ label: PropTypes.string, to: PropTypes.string }),
    mediaQuery: PropTypes.arrayOf(PropTypes.object),
    textColor: PropTypes.string.isRequired,
};

export default Media;
