import ImageInlineBlock from 'libs/wordpress/content/cmsBlocks/ImageInlineBlock';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import moduleMargins from 'config/theme/moduleMargins';
import { moduleSettingsProp } from 'utils/proptypes/modules/settingsProps';

const ImageModule = ({ last = false, settings = {}, ...rest }) => {
    const { background_color: backgroundColor, text_color: textColor } = settings;

    return (
        <MaxWidthWrapper
            includeContentMargins
            as="section"
            backgroundColor={backgroundColor}
            borderBottom={!last ? `1px solid ${colors.black}` : null}
            py={moduleMargins.md}
        >
            <ImageInlineBlock moduleTextColor={textColor} {...rest} />
        </MaxWidthWrapper>
    );
};

ImageModule.propTypes = {
    last: PropTypes.bool,
    settings: moduleSettingsProp,
};

export default ImageModule;
