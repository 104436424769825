import Media from 'components/contentBlocks/Media';
import PropTypes from 'prop-types';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import transformLink from 'libs/wordpress/utils/transformLink';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

// Pure CMS data transformation before we send it to the "real" block!
const ImageInlineBlock = ({ content, isColumn = false, moduleTextColor = '' }) => {
    const { background_image: backgroundObj, link, settings } = content;

    // Transform link data
    let transformedLink;
    if (activeSetting(settings, 'link') && link) {
        transformedLink = transformLink(link);
    }

    // Transform image data
    const isAboveSm = useAboveBreakpoint('sm');
    const transformedBackground = transformFlexibleBackground(backgroundObj, 'image');

    // Transform color data
    const ligthTheme = moduleTextColor === 'white';
    const textColor = ligthTheme ? colors.white : colors.black;

    return (
        <Media
            background={isAboveSm ? transformedBackground.desktop : transformedBackground.mobile}
            backgroundRatio={
                isColumn
                    ? [ratios.vertical, ratios.square, ratios.verticalWide]
                    : [ratios.vertical, null, ratios.horizontal]
            }
            backgroundType="image"
            isColumn={isColumn}
            link={transformedLink}
            mediaQuery={mediaQueries.fullWidth}
            textColor={textColor}
        />
    );
};

ImageInlineBlock.propTypes = {
    content: PropTypes.shape({
        'background_image': PropTypes.exact({
            'desktop_background_image': PropTypes.object,
            'mobile_background_image': PropTypes.object,
        }),
        link: PropTypes.object,
        settings: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isColumn: PropTypes.bool,
    moduleTextColor: PropTypes.string,
};

export default ImageInlineBlock;
